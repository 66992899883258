<template>
  <!-- 库存详情 -->
  <div class="hangOutDetails page-info-content">
    <el-descriptions class="margin-top" title="详情" :column="3" size="medium">
      <el-descriptions-item label="挂牌类型">
        {{ detailObj.orderType === "01" ? "现货" : "库存" }}
      </el-descriptions-item>
      <el-descriptions-item label="库存编码">
        {{ detailObj.inventoryNo }}
      </el-descriptions-item>
      <el-descriptions-item label="品种">
        {{ detailObj.goodsName }}
      </el-descriptions-item>
      <el-descriptions-item label="规格">
        {{ detailObj.specs }}
      </el-descriptions-item>
      <el-descriptions-item label="材质">
        {{ detailObj.materials }}
      </el-descriptions-item>
      <el-descriptions-item label="件重(吨)">
        {{ detailObj.weights }}
      </el-descriptions-item>
      <el-descriptions-item label="产地">
        {{ detailObj.productions }}
      </el-descriptions-item>
      <el-descriptions-item label="仓库">
        {{ detailObj.warshouse }}
      </el-descriptions-item>
      <el-descriptions-item label="厂家">
        {{ detailObj.cmpName }}
      </el-descriptions-item>
      <el-descriptions-item label="交收方式">
        {{ detailObj.csgType === "01" ? "卖方配送" : "买方自提" }}
      </el-descriptions-item>
      <el-descriptions-item label="交收地">
        {{ detailObj.csgPlace }}
      </el-descriptions-item>
      <el-descriptions-item label="开票方式">
        {{ detailObj.invoiceType === "01" ? "一票制" : "多票制" }}
      </el-descriptions-item>
      <el-descriptions-item label="价格">
        {{ detailObj.orderPrice | numberToCurrency }}
      </el-descriptions-item>
      <!-- <el-descriptions-item label="数量">
        {{ detailObj.orderWeight }}
      </el-descriptions-item> -->
      <el-descriptions-item label="有效期">
        {{ detailObj.valiDate }}
      </el-descriptions-item>
      <!-- <el-descriptions-item label="剩余数量">
        {{ detailObj.orderWeightLeft||0 }}
      </el-descriptions-item> -->
    </el-descriptions>
    <!-- 库存详情 -->
    <div v-if="activePage === 0">
      <el-descriptions
        class="margin-top"
        title="商品图片"
        :column="3"
        size="medium"
      />
      <div v-for="(item, index) in imageReqList" :key="index" class="imageBox">
        <img
          :src="$fileUrl + item.imgUrl"
          alt="图片"
          @click="
            imgSrc = item.imgUrl;
            imgFormVisible = true;
          "
        >
      </div>
    </div>
    <el-dialog
      title="图片"
      :close-on-click-modal="false"
      :visible.sync="imgFormVisible"
      width="62%"
      class="dialog"
      @click="editFormVisible = false"
    >
      <img class="image" :src="$fileUrl + imgSrc" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { timestampToTime } from '@/utils/util'
import { ordersDetails } from '@/api/hangOutManagement.js'
export default {
  data() {
    return {
      pnid: '',
      imgFormVisible: false,
      imgSrc: '',
      stockDetail: {},
      imageReqList: [],
      activePage: 0,
      detailObj: {},
      breadcrumbItem: [
        { path: '/', name: '首页' },
        { path: '/hangOutManagement/index', name: '挂牌管理' },
        { path: '', name: '详情' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      ordersDetails({ id: this.$route.query.id }, (res) => {
        this.detailObj = { ...res.data }
        this.detailObj.valiDate = timestampToTime(this.detailObj.valiDate)
        this.imageReqList = [...this.detailObj.images]
      })
    }
  },
  methods: {
    open() {
      this.$refs.ProductNamePopUpLayer.dialogTableVisible = true
    }
  }
}
</script>

<style lang="scss">
.hangOutDetails {
  .image {
    display: block;
    width: 100%;
  }

  .imageBox {
    margin: 12px 0 0 32px;
    display: inline-block;
    height: 125px;
    width: 180px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .buttonCombination {
    margin-top: 32px;
    text-align: center;
  }

  .el-descriptions {
    padding: 32px 32px 0 !important;
  }

  .el-descriptions:last-of-type {
    padding: 12px 32px 0 !important;
  }

  .specificItem .el-descriptions {
    padding: 0 32px !important;
  }

  .buttonCombination .button {
    margin-right: 32px;
  }

  .el-descriptions-item__content input {
    /* width: 100px; */
    height: 32px;
    border: 1px solid #ededed;
    outline: 0;
    transform: translateY(-10px);
    text-indent: 6px;
  }

  .el-descriptions {
    padding: 40px 32px;
  }

  .el-descriptions:last-of-type {
    padding: 0 32px;
  }

  .el-descriptions-row td {
    padding-bottom: 28px !important;
  }

  .el-descriptions .el-descriptions__body .link {
    color: rgb(64, 158, 255);
    text-decoration: underline;
    cursor: pointer;
  }

  .el-descriptions .el-descriptions__body {
    padding: 0 32px;
  }
}
</style>
